:root {
    --DataGrid-overlayHeight: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.markdown img {
  max-width: 100%;
}

.markdown code {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 2px 4px;
  margin: 0px 2px;
  border-radius: 4px;
}

.markdown pre > code {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  border-radius: 0;
}

.MuiTabs-flexContainer {
  height: 100%;
}

pre:has(> code) {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

p {
  margin: 0;
}

a {
  color: #7b65ff;
  font-weight: 600;
}

div.MuiAccordion-gutters::before {
  opacity: 0;
}

.MuiSnackbarContent-action {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiDataGrid-cell > a {
  font-size: 13px;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 12px 0px !important;
}

.MuiDataGrid-toolbarContainer {
  padding: 0 !important;
}

.MuiDataGrid-toolbarQuickFilter {
  height: 48px !important;
  padding-bottom: 0 !important;
}

.MuiDataGrid-toolbarQuickFilter > .MuiInputBase-root {
  height: 48px !important;
}

.MuiDataGrid-toolbarQuickFilter > .MuiInputBase-root > .MuiInputBase-input {
  padding: 0 !important;
  margin-left: 8px !important;
}

.MuiDataGrid-bottomContainer {
  height: 0px !important;
}

.MuiDataGrid-filler {
  height: 0px !important;
}

.MuiDataGrid-overlayWrapperInner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 60px !important;
  width: auto;
}

.MuiDataGrid-overlayWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px !important;
  width: 100% !important;
}

.MuiSelect-select {
  text-align: left;
}

.wmde-markdown,
.wmde-markdown-var {
  color-scheme: light !important;
}

.w-md-editor-text {
  min-height: 100% !important;
}

.file-dropzone {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  align-items: start;
}

.file-dropzone > .MuiBox-root:first-of-type {
  min-height: 0 !important;
  padding: 16px;
}

.file-dropzone.error > .MuiBox-root:first-of-type {
  border-color: #d32f2f;
}

.file-dropzone > .MuiBox-root:first-of-type > .MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.file-dropzone > .MuiTypography-subtitle1 {
  margin-top: 16px;
  margin-bottom: 4px;
}

.file-dropzone > .MuiBox-root > .MuiBox-root > .MuiTypography-h5 {
  margin: 0px;
}

.firebase-emulator-warning {
  display: none;
}